$env: production;
@import '@/styles/_component-base.styles.scss';

.pdpCarousel {
    display: flex;
    overflow: visible;
}

// needed because position: sticky doesn't seem to work with a parent that is display: flex
.pdpCarouselThumbnailWrapper, .pdpCarouselCarouselAssetWrapper {
    display: block;
}

.pdpCarouselThumbnails {
    width: spacer(7xl);
    margin-right: spacer(base);
    overflow: visible;
    position: sticky;
    top: 0;
}

.pdpCarouselThumbnailsImage {
    aspect-ratio: 4/3;
    display: block;
    height: 100%;
    max-width: 512px;
    width: 100%;
}

.pdpCarouselThumbnail {
    width: spacer(7xl);
    aspect-ratio: 1/1;
    padding: spacer(2xs);
    border: 1px solid transparent; // to keep same boundaries as with border
}

.pdpCarouselThumbnailActive {
    border: 1px solid color(action, base);
}

.pdpCarouselThumbnailVideo {
    & img {
        margin-top: -(spacer(3xs));
        margin-bottom: -(spacer(xs)); // fix for extra margin that doesn't seem to have a cause
    }
}

.pdpCarouselThumbnailVideoOverlay {
    position: absolute;
    width: 35px; // exact value since thumbnail is 70px wide
    height: 35px;
    top: 25%; // allows for vertical centering
    left: 25%; // allows for horizontal centering
    color: color(contrast, white);

    @include breakpoint(md) {
        width: 55px; // exact value since thumbnail is 100px wide
        height: 55px;
    }
}

.pdpCarouselThumbnailTextOverlay {
    position: absolute;
    background: rgba(57, 57, 57, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    inset: 0;
    height: 92%;
    width: 92%;
    top: 4%;
    left: 4%;
    color: color(contrast, white);
    font-weight: normal;
    font-size: set-fontSize(16);
}

.pdpCarouselThumbnailTextOverlay:hover {
    cursor: pointer;
}

.pdpCarouselAssets {

    width: 400px;

    @include breakpoint(xl) {
        width: 512px;
    }
}
.pdpCarouselAssetsPicture {
    max-width: 100%;
    height: 100%;
    aspect-ratio: 4/3;
}

.pdpCarouselAssetsPicture img, .pdpCarouselAssetsPicture src {
    display: block;
    max-width: 512px;
    aspect-ratio: 4/3;
    width: 100%;
    height: 100%;
    margin-bottom: spacer(xs);
}

.pdpCarouselAsset {
    width: 400px;

    @include breakpoint(xl) {
        width: 512px;
    }
    height: 384px;
    aspect-ratio: 4/3;

}

.pdpCarouselVideo {
    width: 512px;
    height: 384px;
    aspect-ratio: 4/3;
}


// to minimize layout shift when lazy loading
// the suffix "-wrapper" is automatically added so that's why it's not quite BEM
.pdpCarouselVideoWrapper {
    width: 100%;
    height: 100%;
    aspect-ratio: 4/3;
    margin-bottom: spacer(base);
}

.carouselVideoLazyLoadContainerWrapper {
    width: 100%;
    height: 100%;
    aspect-ratio: 4/3;

    video {
        width: 512px;
        height: 384px;
        aspect-ratio: 4/3;
    }
}

.deliveryAndGMAContainer {
    margin-bottom: spacer(base);
    @include breakpoint(lg) {
        padding-bottom: spacer(2dot5xl) 0 spacer(3dot75xl);
    }
}

.serviceValuePropsReducedPadding {
    @include breakpoint(lg) {
        padding: 0 0 spacer(lg) !important;
    }
}
