$env: production;
@import '@/styles/_component-base.styles.scss';

.container {
    background-color: #f5f6f6;
    padding: spacer(xs);
    margin-bottom: spacer(md);
    margin-top: spacer(md);

    @include breakpoint(md) {
        margin: spacer(md) 0;
    }
    
    @include breakpoint(lg) {
        margin: -(spacer(md)) 0 0;
    }
}

.copy {
    color: #2b51a3;
    font-weight: 400;
    text-align: center;
}

.copyLargeScreen {
    @include breakpoint(lg) {
        margin: 0 spacer(md);
        font-size: set-fontSize(20);
    }
}
