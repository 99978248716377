$env: production;
@import '@/styles/_component-base.styles.scss';

.subtotal {
    display: flex;
    align-items: baseline;
    margin-bottom: spacer(2xs);
    justify-content: space-between;
}

.text {
    font-weight: 400;
    font-size: set-fontSize(24);
    line-height: 32px;
}

.finalPrice {
    font-weight: normal;
    line-height: 32px;
    font-size: set-fontSize(24);
}

.strikethroughPrice {
    font-weight: 300;
    color: color(text, recede);
    font-size: set-fontSize(24);
    line-height: 32px;
    text-decoration: line-through;
}

.badge {
    vertical-align: text-bottom;
    margin-right: spacer(xs); /* fixes text overflowing to new line on very small mobile devices */
    font-size: set-fontSize(12);
    height: 20px;
    @media screen and (min-width: 360px) {
        margin-right: spacer(base);
    }
}

.fontSizeWithPromoPill {
    font-size: set-fontSize(20);
}
