$env: production;
@import '@/styles/_component-base.styles.scss';

.outOfStockMessage {
    background-color: color(accent, blueLight);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    width: 100%;
    max-height: max-content;
    margin-bottom: spacer(sm);
}

.wrapper {
    display: flex;
    justify-content: center;
}

.message {
    place-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: spacer(lg);
    gap: spacer(2xs);
}

.text {
    text-align: center;
    margin-bottom: 0;
}

.button {
    margin: 0 spacer(lg) spacer(lg);
    font-size: set-fontSize(14);
    border-color: color(accent, blue);
    background-color: color(accent, blue);

    &:active, &:focus, &:hover {
        background-color: lighten(color(accent, blue), 20%);
        border-color: lighten(color(accent, blue), 20%);
    }
}
