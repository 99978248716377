$env: production;
@import '@/styles/_component-base.styles.scss';

.containerBreakout {
    width: 100vw;
    margin: 0 0 spacer(sm) -1.25rem;

    @include breakpoint(sm) {
        margin-left: calc(-1 * ((100vw - 540px) / 2));
        width: 100vw;
    }

    @include breakpoint(md) {
        margin-left: calc(-1 * ((100vw - 680px) / 2));
        width: 100vw;
    }

    @include breakpoint(lg) {
        margin-left: 0;
        width: 100%;
    }
}

// This is implemented for EXP.LAYER_PDP_GALLERY.EX-172. If there are less than 6 images the justify-content changes to center and the first thumbnail
// looks cropped in mobile
ul.showLayerDiagram {
    justify-content: left;
    overflow-x: scroll;

    @include breakpoint(sm) {
        justify-content: center;
        overflow-x: hidden
    }
}